import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "./routers";

function App() {
  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <React.Fragment>
                  <>
                    <route.component />
                  </>
                </React.Fragment>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default App;
