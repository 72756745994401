import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function Home() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams.get("code"));
  }, []);
  return (
    <div>
      <h1>Home Page</h1>
    </div>
  );
}
export default Home;
