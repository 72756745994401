import React from "react";
import Home from "../Home";
import CallBack from "../CallBack";
import Test from "../Test";
const routes = [
  {
    exact: true,
    path: "/",
    component: Home,
    layout: React.Fragment,
  },
  // quy dinh path nào sẽ chỉ đến component nào (.jsx)
  { exact: true, path: "/", component: Home },
  { exact: true, path: "/oauth/callback", component: CallBack },
  { exact: true, path: "/test", component: Test },
  // { exact: true, path: "/table", component: CloneFor },
];

export default routes;
