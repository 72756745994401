import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function CallBack() {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(async () => {
    const code = decodeURIComponent(searchParams.get("code"));
    await fetch(`http://localhost:8080/auth/exchange`, {
      method: "POST",
      headers: { Authorization: `Bear ${code}` },
      credentials: "include",
    })
      .then((response) => response.json()) // Chuyển đổi response thành JSON
      .then((data) => {
        console.log("userId:", data.userId); // Lấy và in giá trị userId từ JSON
        console.log("SheetID: ", data.sheetId);

        localStorage.setItem("userData", data.userId);
      });
  }, []);

  return;
}

export default CallBack;
